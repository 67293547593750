import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text, Badge } from "../../components/Core";





const Trust = () => (
  <>
    <Section bg="#f1f1f1">
      <Container>
        <Row className="justify-content-center align-items-center">
      <Col>
    
      </Col>
      
        </Row>
      </Container>
    </Section>
  </>
);

export default Trust;
